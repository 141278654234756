.admin__header {
  width: 100%;
  padding: 20px 0px;
  background: var(--primary-color);
}

.admin__nav-wrapper-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin__nav-top-right img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
}

.admin__nav-top-right{
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.search__input{
    padding: 20px;
}

.admin__nav-top-right span i{
    color: whitesmoke;
    cursor: pointer;
}
.logo{
    cursor: pointer;
    color: whitesmoke;
}


.admin__menu{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: var(--card-bg-02);
}

.admin__navigation{
    width: 100%;
    
}

.admin__menu-list{
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.admin__menu-list {
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 50px;
}
.admin__menu-item a{
    padding: 10px 17px;
}
.active__admin-menu{
    background: whitesmoke;

}





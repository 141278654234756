.billing__form .form__group input{
    border: 1px solid var(--card-bg-02);
}

.checkout__cart{
    padding: 20px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 5px;
}

.checkout__cart h6, h4{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.checkout__cart h6{
    margin-bottom: 20px;
}

.checkout__cart h4{
    border-top: 1px solid rgba(221,221,221,0.253);
    padding-top: 20px;
}

.auth__btn{
    background: #fff;
    color: #000;
    font-weight: bold;
}

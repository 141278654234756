.auth__form{
    background: var(--primary-color);
    padding: 40px;
    border-radius: 5px;
    width: 100%;
}

.auth__btn{
    background: #fff;
    color: var(--primary-color);
    font-weight: 600;
}

.auth__form p{
    margin-top: 30px;
}

.auth__form p a{
    color: rgba(255,255,255,.735);
}

.auth__form input[type='file']{
    color:#fff;
    cursor: pointer;

}